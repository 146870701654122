import React from 'react';
import PropTypes from 'prop-types';

import { useBiggestHeightFromListOfRefs } from '../../../../../../hooks/useBiggestHeightFromListOfRefs';

import { CTAWrapper, Logo, SponsorTitle } from '../../innerComponents';

export function SPEXCategoryLogoWithSponsorTitle(props) {
  const { organisation = {}, posIndex, listSponsorTitleRefs } = props;
  const biggestTitleHeight =
    useBiggestHeightFromListOfRefs(listSponsorTitleRefs);

  return (
    <CTAWrapper organisation={organisation}>
      <SponsorTitle
        content={organisation.sponsorTitle}
        ref={(el) => (listSponsorTitleRefs.current[posIndex] = el)}
        style={{ minHeight: biggestTitleHeight }}
      />
      <Logo logoPath={organisation.logo?.path} />
    </CTAWrapper>
  );
}

SPEXCategoryLogoWithSponsorTitle.propTypes = {
  organisation: PropTypes.shape({
    logo: PropTypes.shape({
      path: PropTypes.string,
    }),
    sponsorTitle: PropTypes.string,
  }),
  posIndex: PropTypes.number,
  listSponsorTitleRefs: PropTypes.object,
};
