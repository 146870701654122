import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getStyledPropertyValue } from '../../../../helpers';

import { CTAWrapper, Name, Logo } from '../../innerComponents/';

import { WrapperImageBg } from '../../../sharedComponents';

const setHoverColor = ({ theme }) =>
  getStyledPropertyValue(theme, theme.module.dStyles?.settings?.hoverColor);

const Wrapper = styled.div`
  height: 100%;
  &:hover {
    ${WrapperImageBg} {
      border-color: ${setHoverColor};
    }
    ${Name} {
      background-color: ${setHoverColor};
    }
    a {
      background-color: ${setHoverColor};
    }
  }
`;

export function SPEXCategoryLogoWithNames({ organisation }) {
  return (
    <Wrapper>
      <CTAWrapper organisation={organisation}>
        <Logo logoPath={organisation.logo?.path} />
        <Name>{organisation.name}</Name>
      </CTAWrapper>
    </Wrapper>
  );
}

SPEXCategoryLogoWithNames.propTypes = {
  organisation: PropTypes.shape({
    logo: PropTypes.shape({
      path: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
};
