import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';
import { selectPageConfig } from '../../../../../../../store/features/pageConfig';
import { Anchor } from '../../../../../../shared/Anchor';

const Wrapper = styled.div`
  margin-bottom: 20px;

  table {
    width: 100%;

    thead {
      th {
        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.dStyles.elements?.body?.regular?.title,
          )}
      }

      + tbody {
        tr:first-child {
          td {
            padding-top: 18px;
          }
        }
      }
    }

    tbody {
      td {
        &:last-child {
          width: 100px;
        }
      }
    }

    a {
      display: block;
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.body?.regular?.anchor,
        )}
    }
  }
`;

export default function SponsorGroup(props) {
  const { sponsors, companyTitle, standTitle, showTitles } = props;
  const { sitePath } = useSelector(selectPageConfig);

  return (
    <Wrapper>
      <table>
        {showTitles && (
          <thead>
            <tr>
              <th>{companyTitle}</th>
              <th>{standTitle}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {sponsors.map((sponsor, index) => {
            const {
              url,
              openInNewTabEnabled,
              linkToExternalPageEnabled,
              name,
              path,
              location,
            } = sponsor;

            return (
              <tr key={index}>
                <td>
                  <Anchor
                    to={linkToExternalPageEnabled ? url : `/sponsors/${path}/`}
                    pathPrefix={sitePath}
                    openInNewTab={openInNewTabEnabled}
                  >
                    {name}
                  </Anchor>
                </td>
                <td>{location}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
}

SponsorGroup.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      url: PropTypes.string,
      location: PropTypes.string,
      featured: PropTypes.bool,
      openInNewTabEnabled: PropTypes.bool,
      linkToExternalPageEnabled: PropTypes.bool,
      sponsorTitle: PropTypes.string,
    }),
  ).isRequired,
  companyTitle: PropTypes.string.isRequired,
  standTitle: PropTypes.string.isRequired,
  showTitles: PropTypes.bool,
};
