import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

const Wrapper = styled.div`
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;

const AZFilter = styled.div`
  margin-bottom: 40px;
`;

const CategoryFilter = styled.div`
  margin-bottom: 40px;
`;

export default function Layout(props) {
  const { filter, showFilter, azFilter, showAZFilter, children } = props;

  const bodyGridClassName = cn({
    'col-xs-12': !showFilter,
    'col-xs-12 col-sm-8 col-md-9': showFilter,
  });

  return (
    <Wrapper>
      <div className="container">
        {showAZFilter && (
          <div className="row">
            <div className="col-xs-12">
              <AZFilter>{azFilter}</AZFilter>
            </div>
          </div>
        )}
        <div className="row">
          {showFilter && (
            <div className="col-xs-12 col-sm-4 col-md-3">
              <CategoryFilter>{filter}</CategoryFilter>
            </div>
          )}
          <div className={bodyGridClassName}>{children}</div>
        </div>
      </div>
    </Wrapper>
  );
}

Layout.propTypes = {
  filter: PropTypes.element,
  azFilter: PropTypes.element,
  showFilter: PropTypes.bool,
  showAZFilter: PropTypes.bool,
};
