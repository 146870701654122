import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

export const Name = styled.p`
  display: block;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.category?.title,
    )}
`;
