import { SiteModule, SiteModuleCTA } from '../../../../types';
import { SiteModuleTypes } from '../../../../constants';

export enum SPEXModuleDisplayStyles {
  SCROLLING_LOGOS = 'SCROLLING_LOGOS',
  LOGOS = 'LOGOS',
  LOGOS_AND_TEXT = 'LOGOS_AND_TEXT',
}

export enum SPEXModuleTitleTypes {
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
}

export enum SPEXModuleRotateTypes {
  SINGLE = 'SINGLE',
  SMOOTH = 'SMOOTH',
  PAGE = 'PAGE',
}

export enum SPEXModuleLayoutTypes {
  FULLSCREEN = 'FULLSCREEN',
}

export type SponsorCategory = {
  name: string;
  path: string;
  location: string;
  url: string;
  featured: boolean;
  openInNewTabEnabled: boolean;
  sponsorTitle: string;
  logo: {
    path: string;
  };
};

export interface SPEXModuleType extends SiteModule {
  '@type': SiteModuleTypes.EventSpexCategorySection;
  link: SiteModuleCTA;
  rotateType: SPEXModuleRotateTypes;
  style: SPEXModuleDisplayStyles;
  title: string;
  titleAlignment: 'LEFT' | 'CENTRE' | 'RIGHT';
  titleType: SPEXModuleTitleTypes;
  layoutType: SPEXModuleLayoutTypes;
  customTitle: string;
  sponsorsCategories: SponsorCategory[];
}
