import React, { useContext, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import { devices, renderStyledElementStyles } from '../../../../helpers';

import {
  SPEXCategoryLogo,
  SPEXCategoryLogoWithNames,
  SPEXCategoryLogoWithSponsorTitle,
  SPEXCategoryText,
  SPEXCategoryTiered,
} from '../../displayStyles';

const ColumnWrapper = styled.div`
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;

const TieredColumnWrapper = styled(ColumnWrapper)`
  @media (min-width: ${devices.breakpoints.tablet}) {
    &.col-sm-2 {
      flex-basis: 20%;
      max-width: 20%;
    }
    &.col-sm-1 {
      flex-basis: 14.28%;
      max-width: 14.28%;
    }
  }
`;

export const LogosContent = ({ logoSize, organisations, style }) => {
  const listSponsorTitleRefs = useRef([]);
  const themeConfig = useContext(ThemeContext);

  const dStyles = themeConfig.module.dStyles;
  const { settings: { xs, sm } = {} } = dStyles;

  return (
    <div className="row" data-testid="spex-category-logos-content">
      {organisations.map((organisation, posIndex) => {
        switch (style) {
          case 'LOGOS_AND_TEXT':
            return (
              <ColumnWrapper
                className={`col-xs-${xs} col-sm-${sm}`}
                key={posIndex}
              >
                <SPEXCategoryLogoWithNames organisation={organisation} />
              </ColumnWrapper>
            );
          case 'LOGOS_AND_SPONSOR_TITLE':
            return (
              <ColumnWrapper
                className={`col-xs-${xs} col-sm-${sm}`}
                key={posIndex}
              >
                <SPEXCategoryLogoWithSponsorTitle
                  organisation={organisation}
                  listSponsorTitleRefs={listSponsorTitleRefs}
                  posIndex={posIndex}
                />
              </ColumnWrapper>
            );
          case 'LOGOS':
            return (
              <ColumnWrapper
                className={`col-xs-${xs} col-sm-${sm}`}
                key={posIndex}
              >
                <SPEXCategoryLogo organisation={organisation} />
              </ColumnWrapper>
            );
          case 'TEXT':
            return (
              <ColumnWrapper
                className={`col-xs-${xs} col-sm-${sm}`}
                key={posIndex}
              >
                <SPEXCategoryText organisation={organisation} />
              </ColumnWrapper>
            );
          case 'TIERED': {
            return (
              <TieredColumnWrapper
                className={`col-xs-${xs?.[logoSize]} col-sm-${sm?.[logoSize]}`}
                key={posIndex}
              >
                <SPEXCategoryTiered
                  organisation={organisation}
                  logoSize={logoSize}
                  key={posIndex}
                />
              </TieredColumnWrapper>
            );
          }
          default:
            return null;
        }
      })}
    </div>
  );
};

LogosContent.propTypes = {
  logoSize: PropTypes.string,
  organisations: PropTypes.array,
  style: PropTypes.string,
};
