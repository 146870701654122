import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements?.filter)}
`;

const Title = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.filter?.title,
    )}

  .clear {
    cursor: pointer;
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.filter?.clear,
      )}
  }
`;

const CheckboxFilter = styled.label`
  display: flex;

  :not(:disabled) {
    cursor: pointer;
  }

  .label {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.filter?.checkbox?.label,
      )}
  }

  input {
    position: relative;
    margin: 0;
    appearance: none;

    :not(:disabled) {
      cursor: pointer;
    }

    ::before {
      position: absolute;
      content: '';

      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.filter?.checkbox?.input?.border,
        )}
    }

    ::after {
      position: absolute;
      content: '';
      transform: scale(0, 0);
      transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);

      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.filter?.checkbox?.input?.fill,
        )}
    }

    :checked {
      ::after {
        transform: scale(1, 1);

        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.dStyles.elements?.filter?.checkbox?.input?.fill
              ?.selected,
          )}
      }
    }
  }
`;

export default function Filter({ categories, selectedValues, onChange }) {
  const handleChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      onChange([...selectedValues, value]);
    } else {
      onChange(selectedValues.filter((category) => category !== value));
    }
  };

  const handleClear = () => {
    onChange([]);
  };

  return (
    <Wrapper>
      <Title>
        <span>Categories</span>
        <span onClick={handleClear} className="clear">
          Clear
        </span>
      </Title>
      {categories.map((category) => (
        <CheckboxFilter key={category}>
          <input
            type="checkbox"
            value={category}
            checked={selectedValues.includes(category)}
            onChange={handleChange}
          />
          <span className="label">{category}</span>
        </CheckboxFilter>
      ))}
    </Wrapper>
  );
}

Filter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
