import React from 'react';
import PropTypes from 'prop-types';

import { CTAWrapper, Logo } from '../../innerComponents';

export const SPEXCategoryLogo = ({ organisation }) => (
  <CTAWrapper organisation={organisation}>
    <Logo logoPath={organisation.logo?.path} />
  </CTAWrapper>
);

SPEXCategoryLogo.propTypes = {
  organisation: PropTypes.shape({
    logo: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
};
