import React, { useContext } from 'react';
import { CTAWrapper } from '../../innerComponents';
import styled, { ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { getOrganisationsWithLogos, getUniqOrganisations } from './helpers';
import { StyledHeading, StyledImageTag } from '../../../sharedComponents';

import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import AutoScroll from 'embla-carousel-auto-scroll';
import {
  SPEXModuleLayoutTypes,
  SPEXModuleRotateTypes,
  SPEXModuleTitleTypes,
  SPEXModuleType,
  SponsorCategory,
} from '../../types';
import s from './index.module.scss';

const rotateSliderSettings = {
  [SPEXModuleRotateTypes.SINGLE]: {
    loop: true,
    slidesToScroll: 1,
    speed: 2,
  },
  [SPEXModuleRotateTypes.SMOOTH]: {
    loop: true,
    speed: 2,
  },
  [SPEXModuleRotateTypes.PAGE]: {
    loop: true,
    slidesToScroll: 5,
    speed: 2,
  },
};

const Wrapper = styled.div`
  .container-fluid {
    padding: 0;
  }

  overflow: hidden;
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)};
`;

const CustomTitle = styled.div`
  margin-bottom: 40px;
  text-align: center;
`;

const Viewport = styled.div`
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(10px * -1);
`;

export interface ScrollingLogosProps {
  section: SPEXModuleType;
}

function addFakeElements(array: SponsorCategory[], desiredLength: number) {
  if (array.length < desiredLength) {
    const fakeElements = Array(desiredLength - array.length).fill({ id: '' });
    return array.concat(fakeElements);
  } else {
    return array;
  }
}

export default function ScrollingLogos(props: ScrollingLogosProps) {
  const {
    section: {
      style,
      layoutType,
      rotateType,
      titleType,
      customTitle,
      sponsorsCategories,
    },
  } = props;

  const sliderSettings = rotateSliderSettings[rotateType];
  const autoplaySettings = Autoplay({
    playOnInit: true,
    stopOnMouseEnter: true,
    stopOnInteraction: false,
    delay: 3500,
  });
  const autoscrollSettings = AutoScroll({
    playOnInit: true,
    stopOnMouseEnter: true,
    stopOnInteraction: false,
    stopOnFocusIn: false,
  });
  const platType =
    rotateType === SPEXModuleRotateTypes.SMOOTH
      ? autoscrollSettings
      : autoplaySettings;
  const [emblaRef] = useEmblaCarousel(sliderSettings, [platType]);

  const themeConfig = useContext(ThemeContext);
  const dStyles =
    themeConfig.siteBuilderModules.spexCategoryModule.displayStyles[style];
  const vStyles =
    themeConfig.siteBuilderModules.spexCategoryModule.visualStyles;

  const organisations = getUniqOrganisations(sponsorsCategories);
  const organisationsWithLogos = getOrganisationsWithLogos(organisations);

  const layoutClassName =
    layoutType === SPEXModuleLayoutTypes.FULLSCREEN
      ? 'container-fluid'
      : 'container';

  const organisationsWithFakeElements = addFakeElements(
    organisationsWithLogos,
    6,
  );

  return (
    <Wrapper>
      {titleType === SPEXModuleTitleTypes.CUSTOM && customTitle && (
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <CustomTitle>
                <StyledHeading
                  content={customTitle}
                  align="CENTRE"
                  displayStyles={dStyles}
                  visualStyles={vStyles}
                />
              </CustomTitle>
            </div>
          </div>
        </div>
      )}
      <div className={layoutClassName}>
        <Viewport ref={emblaRef}>
          <Container>
            {organisationsWithFakeElements.map(
              (organisation: SponsorCategory, index: number) => (
                <div className={s.embla__slide} key={index}>
                  <CTAWrapper organisation={organisation} key={index}>
                    <StyledImageTag
                      src={organisation?.logo?.path}
                      displayStyles={dStyles}
                      lazy={false}
                      alt={organisation.name}
                      onClick={null}
                      visualStyles={{}}
                    />
                  </CTAWrapper>
                </div>
              ),
            )}
          </Container>
        </Viewport>
      </div>
    </Wrapper>
  );
}
