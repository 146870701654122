import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { StyledBackgroundImage } from '../../../sharedComponents';

export function Logo(props) {
  const { logoPath } = props;
  const themeConfig = useContext(ThemeContext);

  return (
    <StyledBackgroundImage
      path={logoPath}
      imgixParams="w=480&h=210"
      dStyles={themeConfig.module.dStyles.elements?.category?.logo}
    />
  );
}

Logo.propTypes = {
  logoPath: PropTypes.string.isRequired,
};
