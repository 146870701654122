import uniqBy from 'lodash/uniqBy';

export function getUniqOrganisations(sponsorsCategories) {
  let organisations = [];

  sponsorsCategories.forEach((category) => {
    organisations = organisations.concat(category.organisations);
  });

  return uniqBy(organisations, 'name');
}

export function getOrganisationsWithLogos(organisations) {
  return organisations.filter((organisation) => organisation.logo);
}
