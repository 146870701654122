import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { divideArrayIntoParts } from '../../../../../../helpers';
import Layout from './Layout';
import Filter from './Filter';
import AZFilter from './AZFilter';
import SponsorGroup from './SponsorGroup';
import FeaturedSponsor from './FeaturedSponsor';

export function getFilteredSponsors(sponsorConfig, categories, azValue) {
  let sponsors = [];
  const allCategories = sponsorConfig.map((category) => category.title);
  const selectedCategories = categories.length > 0 ? categories : allCategories;

  // Filter by categories
  sponsorConfig.forEach((category) => {
    if (selectedCategories.includes(category.title)) {
      sponsors = sponsors.concat(category.organisations);
    }
  });

  // Filter by A-Z value
  if (azValue !== '') {
    sponsors = sponsors.filter((sponsor) => {
      if (azValue === '#') {
        return /\d/.test(sponsor.name[0]);
      }

      return sponsor.name[0].toUpperCase() === azValue;
    });
  }

  return sponsors;
}

export default function ExhibitorListings(props) {
  const {
    section: {
      showAZSelector,
      showCategorySelector,
      sponsorsCategories,
      showTitles,
      standText,
    },
  } = props;

  const [selectedAZValue, setSelectedAZValue] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleAZFilterChange = useCallback((value) => {
    setSelectedAZValue(value);
  }, []);

  const handleCategoryFilterChange = useCallback((values) => {
    setSelectedCategories(values);
  }, []);

  const filteredSponsors = useMemo(() => {
    return getFilteredSponsors(
      sponsorsCategories,
      selectedCategories,
      selectedAZValue,
    );
  }, [selectedAZValue, selectedCategories, sponsorsCategories]);

  const featuredSponsors = filteredSponsors.filter(
    (sponsor) => sponsor.featured,
  );
  const regularSponsors = filteredSponsors.filter(
    (sponsor) => !sponsor.featured,
  );

  const standTitle = standText || 'Stand';
  const categories = sponsorsCategories.map((category) => category.title);

  const renderSponsorGroup = useCallback(
    (sponsors) => {
      return (
        <SponsorGroup
          sponsors={sponsors}
          companyTitle="Company"
          standTitle={standTitle}
          showTitles={showTitles}
        />
      );
    },
    [showTitles, standTitle],
  );

  const listsCount = showCategorySelector ? 2 : 3;
  const listsCountToDisplay = regularSponsors.length <= 10 ? 1 : listsCount;
  const featuredSponsorsColumnClassName = cn({
    'col-xs-6 col-md-4': showCategorySelector,
    'col-xs-6 col-sm-4 col-md-3': !showCategorySelector,
  });

  return (
    <Layout
      filter={
        <Filter
          categories={categories}
          selectedValues={selectedCategories}
          onChange={handleCategoryFilterChange}
        />
      }
      showFilter={showCategorySelector}
      azFilter={
        <AZFilter
          selectedValue={selectedAZValue}
          onChange={handleAZFilterChange}
        />
      }
      showAZFilter={showAZSelector}
    >
      {featuredSponsors.length > 0 && (
        <div className="row">
          {featuredSponsors.map((sponsor, index) => (
            <div key={index} className={featuredSponsorsColumnClassName}>
              <FeaturedSponsor sponsor={sponsor} standTitle={standTitle} />
            </div>
          ))}
        </div>
      )}
      {regularSponsors.length > 0 && (
        <div className="row">
          {divideArrayIntoParts(regularSponsors, listsCountToDisplay).map(
            (sponsors, index) => (
              <div
                key={index}
                className={`col-xs-12 col-md-${12 / listsCountToDisplay}`}
              >
                {renderSponsorGroup(sponsors)}
              </div>
            ),
          )}
        </div>
      )}
    </Layout>
  );
}

ExhibitorListings.propTypes = {
  section: PropTypes.shape({
    sponsorsCategories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        logoSize: PropTypes.string,
        organisations: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string,
            url: PropTypes.string,
            location: PropTypes.string,
            featured: PropTypes.bool,
            openInNewTabEnabled: PropTypes.bool,
            linkToExternalPageEnabled: PropTypes.bool,
            sponsorTitle: PropTypes.string,
            logo: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
        ),
      }),
    ),
    link: PropTypes.shape({
      type: PropTypes.string,
      selectType: PropTypes.string,
      openInNewTabEnabled: PropTypes.bool,
      shown: PropTypes.bool,
    }),
    showTitles: PropTypes.bool,
    showAZSelector: PropTypes.bool,
    showCategorySelector: PropTypes.bool,
    standText: PropTypes.string,
  }).isRequired,
};
