import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { renderStyledElementStyles } from '../../../../../helpers';
import { selectPageConfig } from '../../../../../../../store/features/pageConfig';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import { Anchor } from '../../../../../../shared/Anchor';

const Wrapper = styled(Anchor)`
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.body?.featured,
    )}
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.body?.featured?.logo,
    )}

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
`;

const Title = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.body?.featured?.title,
    )}
`;

const Stand = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.body?.featured?.stand,
    )}
`;

export default function FeaturedSponsor({ sponsor, standTitle }) {
  const {
    url,
    openInNewTabEnabled,
    linkToExternalPageEnabled,
    name,
    path,
    location,
    logo,
  } = sponsor;
  const { sitePath } = useSelector(selectPageConfig);

  return (
    <Wrapper
      to={linkToExternalPageEnabled ? url : `/sponsors/${path}/`}
      pathPrefix={sitePath}
      openInNewTab={openInNewTabEnabled}
    >
      <Logo>{logo?.path && <ImageTag src={logo.path} lazy={false} />}</Logo>
      <Title>{name}</Title>
      {location && (
        <Stand>
          {standTitle}&nbsp;<span>{location}</span>
        </Stand>
      )}
    </Wrapper>
  );
}

FeaturedSponsor.propTypes = {
  sponsor: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    url: PropTypes.string,
    location: PropTypes.string,
    featured: PropTypes.bool,
    openInNewTabEnabled: PropTypes.bool,
    linkToExternalPageEnabled: PropTypes.bool,
    sponsorTitle: PropTypes.string,
    logo: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
  standTitle: PropTypes.string.isRequired,
};
