import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import { CTAWrapper } from '../../innerComponents';
import { StyledBackgroundImage } from '../../../sharedComponents';

export const SPEXCategoryTiered = ({ organisation, logoSize }) => {
  const themeConfig = useContext(ThemeContext);
  const dStylesLogo = themeConfig.module.dStyles.elements?.category?.logo;

  return (
    <CTAWrapper organisation={organisation}>
      <StyledBackgroundImage
        path={organisation.logo?.path}
        imgixParams="w=480&h=210"
        dStyles={{
          ...dStylesLogo,
          ...dStylesLogo?.settings?.[logoSize],
        }}
      />
    </CTAWrapper>
  );
};

SPEXCategoryTiered.propTypes = {
  logoSize: PropTypes.string,
  organisation: PropTypes.shape({
    logo: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
};
