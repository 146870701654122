import { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../helpers';

export const letters = [
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements?.azFilter)}
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.azFilter?.box,
    )}

  :last-child {
    border-right: 0;
  }

  &.selected {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements?.azFilter?.box?.selected,
      )}
  }
`;

export default function AZFilter({ selectedValue, onChange }) {
  const handleClick = useCallback(
    (e) => {
      onChange(e.target.dataset.value);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <Box
        onClick={handleClick}
        data-value=""
        className={cn({ selected: selectedValue === '' })}
        role="button"
      >
        All
      </Box>
      {letters.map((letter) => (
        <Box
          key={letter}
          onClick={handleClick}
          data-value={letter}
          className={cn({ selected: selectedValue === letter })}
          role="button"
        >
          {letter}
        </Box>
      ))}
    </Wrapper>
  );
}

AZFilter.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
