import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.category?.sponsorTitle,
    )}
`;

export const SponsorTitle = React.forwardRef(({ content, style }, ref) => (
  <Wrapper style={style} ref={ref}>
    <span>{content}</span>
  </Wrapper>
));

SponsorTitle.propTypes = {
  content: PropTypes.string,
  style: PropTypes.object,
};
