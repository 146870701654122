import React from 'react';
import PropTypes from 'prop-types';

import { CTAWrapper, Name } from '../../innerComponents';

export const SPEXCategoryText = ({ organisation }) => (
  <CTAWrapper organisation={organisation}>
    <Name>{organisation.name}</Name>
  </CTAWrapper>
);

SPEXCategoryText.propTypes = {
  organisation: PropTypes.object,
};
